<template>
  <v-dialog v-model="menu" width="300px">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-card-title>
          <span class="text-h5">
            <v-icon large color="black" class="mr-2">mdi-history</v-icon>

            {{ $_t("attribute.loginHistory") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-list v-if="history.length">
            <v-list-item v-for="(h, index) in history" :key="index">
              <v-list-item-title>
                {{ $_date(h, "long") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginHistoryDialog",
  data() {
    return {
      history: [],
      menu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  watch: {
    async menu(value) {
      if (value) {
        this.history = await this.$actions.getLoginHistory({
          publicKey: this.user.publicKey,
        });
      }
    },
  },
};
</script>

<style scoped></style>
