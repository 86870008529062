<template>
  <v-dialog v-model="menu" width="300px">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{ $_t("components.app.headerr.language.change_language") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(language, index) in lang_items"
              :key="index"
              @click="changeLocal(language.key)"
            >
              <v-list-item-avatar size="55">
                <v-img :src="language.flag"></v-img>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ language.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import app from "@/tools/App";

export default {
  name: "SwitchLangDialog",
  data: () => ({
    lang_items: app.lang_items,
    menu: false,
  }),
  props: {},
  methods: {
    changeLocal(key) {
      this.$store.dispatch("lang/setI18nLang", key);
      this.menu = false;
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    click() {
      this.open = true;
    },
  },
};
</script>
