<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4 py-4" :loading="is_loading">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.unReadNotifications")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          @click="makeAllAsRead"
          v-if="unreadNotifications.length"
        >
          {{ $_t("attribute.makeAllAsRead") }}
        </v-btn>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row v-if="unreadNotifications.length">
          <v-col
            cols="12"
            v-for="notif in unreadNotifications"
            :key="notif.notificationId"
          >
            <v-card hover>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h6 text-break">{{
                    notif.message
                  }}</v-card-title>

                  <v-card-subtitle>{{
                    $_date(notif.createMoment, "fromNow")
                  }}</v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      v-if="!notif.isRead"
                      color="primary"
                      @click="makeAsRead({ id: notif.notificationId })"
                    >
                      {{ $_t("attribute.makeAsRead") }}
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="justify-center mb-5">
          <h2>{{ $_t("attribute.notifications_empty") }}</h2>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Notifications",
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      notifications: "notification/notifications",
      user: "authentication/user",
      role: "authentication/role",
    }),
    unreadNotifications() {
      return this.notifications.filter((n) => n.isRead === false) || [];
    },
    modal: {
      get: function () {
        return this.$store.getters["notification/modal"];
      },
      set: function (value) {
        this.$store.commit("notification/setModal", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.commit("notification/setModal", false);
    },
    async makeAllAsRead() {
      // console.log(this.unreadNotifications.map((n) => n.notificationId));
      const res = await this.$actions.readAllNotification({
        ids: this.unreadNotifications.map((n) => n.notificationId),
        publicKey: this.user.publicKey,
      });
      if (res) await this.$actions.getNotifications({ role: this.role });
    },

    async makeAsRead({ id }) {
      // console.log(this.unreadNotifications.map((n) => n.notificationId));
      const res = await this.$actions.readAllNotification({
        ids: [id],
        publicKey: this.user.publicKey,
      });
      if (res) await this.$actions.getNotifications({ role: this.role });
    },
  },
};
</script>

<style scoped></style>
