<template>
  <v-dialog v-model="menu" width="500px">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            $_t("attribute.changePassword")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-4"> </v-card-text>
        <MainSoftForm>
          <template v-slot:body>
            <MyFormGenerator
              lg="10"
              @clickAppend="showPassword"
              :schema="schema"
              :validator="$v"
              :enter="submit"
            ></MyFormGenerator>
          </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="submit"
                  :loading="is_loading"
                  :disabled="is_loading || $v.$error"
                >
                  {{ $_t("attribute.changePassword") }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { CHANGE_PASSWORD } from "@/schema/CHANGE_PASSWORD.schema";
export default {
  name: "ChangePassword",
  components: { MyFormGenerator, MainSoftForm },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      user: "authentication/user",
      token: "authentication/token",
    }),
  },
  data: () => ({
    menu: false,
    schema: CHANGE_PASSWORD.schema,
    form: CHANGE_PASSWORD.madel,
  }),
  validations: {
    ...CHANGE_PASSWORD.validations,
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      const res = await this.$actions.changePassword({
        publicKey: this.user.publicKey,
        oldPassword: this.form.old_password,
        newPassword: this.form.new_password,
      });
      if (res) {
        await this.$actions.renewToken({ token: this.token });
      }
      this.close();
    },
    close() {
      this.menu = false;
      this.clear();
    },
    clear() {
      this.form = {
        old_password: null,
        confrim_password: null,
        new_password: null,
      };
      this.$v.$reset();
    },
    showPassword(id) {
      this.schema[this.getIdWithName(this.schema, id)].showPassword =
        !this.schema[this.getIdWithName(this.schema, id)].showPassword;
    },
  },
};
</script>
