import { required, sameAs, minLength } from "vuelidate/lib/validators";

export const CHANGE_PASSWORD = {
  schema: [
    {
      id: "old_password",
      label: "old_password",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "password",
      showPassword: false,
    },
    {
      id: "new_password",
      label: "new_password",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "password",
      showPassword: false,
    },
    {
      id: "confrim_password",
      label: "confrim_password",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "password",
      showPassword: false,
    },
  ],

  madel: { old_password: null, new_password: null, confrim_password: null },

  validations: {
    form: {
      old_password: { required },
      new_password: { required, minLength: minLength(8) },
      confrim_password: { required, sameAsPassword: sameAs("new_password") },
    },
  },
};
