<template>
  <div class="pt-8">
    <v-row>
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-toolbar-title class="font-weight-bold text-h5 text-md-h4"
          >{{ time }} , {{ user.fullName }}
        </v-toolbar-title>
        <v-toolbar-title class="text--disabled">{{
          $_date(new Date())
        }}</v-toolbar-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-badge
        :content="unreadNotifications.length"
        :value="unreadNotifications.length"
        color="red"
        overlap
      >
        <v-btn
          color="primary"
          @click="$store.commit('notification/setModal', true)"
          icon
          outlined
          large
          dark
        >
          <v-icon large dark>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
      <v-menu bottom min-width="200px" rounded>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            outlined
            large
            class="ml-4"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large dark>mdi-shield-crown-outline</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large>mdi-shield-crown-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $_t(`attribute.${user.role}`)
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="user.loginDates">{{
                  `${$_t("attribute.lastLoginDate")}: ${$_date(
                    user.loginDates[user.loginDates.length - 1],
                    "long"
                  )}`
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <LoginHistoryDialog>
              <template #activator>
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>mdi-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $_t("attribute.loginHistory")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </LoginHistoryDialog>
            <SwitchLangDialog>
              <template v-slot:activator>
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>mdi-translate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $_t("components.app.headerr.language.change_language")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </SwitchLangDialog>
            <change-password>
              <template v-slot:activator>
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>mdi-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $_t("attribute.changePassword")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </change-password>
          </v-list>
        </v-card>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";
import SwitchLangDialog from "@/components/app/SwitchLangDialog";
import ChangePassword from "@/components/app/ChangePassword";
import LoginHistoryDialog from "@/components/app/LoginHistoryDialog";

export default {
  name: "AdminHeader",
  components: { LoginHistoryDialog, ChangePassword, SwitchLangDialog },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      notifications: "notification/notifications",
    }),
    unreadNotifications() {
      if (!this.notifications.length) return [];
      return this.notifications.filter((n) => n.isRead === false);
    },
    time() {
      const hr = new Date().getHours();
      if (hr >= 0 && hr < 12) {
        return _t("attribute.good_morning");
      } else if (hr >= 12 && hr <= 17) {
        return _t("attribute.good_afternoon");
      } else {
        return _t("attribute.good_evening");
      }
    },
  },
};
</script>
