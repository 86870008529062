<template>
  <v-app>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.width > 960"
      v-model="drawer"
      app
      :mini-variant.sync="mini"
      permanent
    >
      <v-row class="justify-center mt-1">
        <v-btn class="float-end" icon @click.stop="mini = !mini">
          <v-icon v-if="mini">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-btn>
      </v-row>
      <div class="my-5 mx-auto">
        <v-img
          v-if="!mini"
          class="mx-auto"
          width="270px"
          contain
          height="60px"
          src="@/assets/img/sidebarNew.png"
        ></v-img>
        <v-img
          v-else
          class="mx-auto"
          width="170px"
          contain
          height="35px"
          src="@/assets/img/iconNew.png"
        ></v-img>
      </div>
      <v-list dense>
        <template v-for="(item, index) in items">
          <template v-if="item.show">
            <v-list-group
              v-if="item.subItems"
              :key="index"
              :id="index"
              eager
              ripple
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-icon :class="mini ? '' : 'ml-7'">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(subItem, i) in item.subItems">
                <v-list-item
                  v-if="subItem.show"
                  :key="i"
                  :active-class="mini ? '' : 'item-active'"
                  :to="subItem.to"
                  link
                >
                  <v-list-item-icon :class="mini ? '' : 'ml-10'">
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-item
              :active-class="mini ? '' : 'item-active'"
              :to="item.to"
              :key="index"
              v-else
              link
            >
              <v-list-item-icon :class="mini ? '' : 'ml-7'">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <v-list-item color="red" @click="isShowVerifyModal = true">
          <v-icon :class="mini ? '' : 'ml-7'" class="red--text"
            >mdi-logout-variant</v-icon
          >
          <span v-if="!mini" class="ml-5 red--text">
            {{ $_t("admin.components.drawer.logout") }}
          </span>
        </v-list-item>
      </v-list>
      <!--     logout btn -->
    </v-navigation-drawer>
    <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <v-btn fab color="red lighten-5" small class="elevation-0 mr-3">
            <v-icon color="red">mdi-logout-variant</v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.logout") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 text-break">
          {{ $_t("attribute.logout_sure") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn color="red darken-1" dark @click="logout">
            {{ $_t("attribute.logout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <v-container class="">
        <v-dialog
          v-model="dialog"
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialog = !dialog"
              v-if="$vuetify.breakpoint.width <= 960"
              small
              class="mx-1 float-end"
              fab
              dark
              color="primary"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon dark> mdi-menu </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary lighten-2">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-img
                  width="170px"
                  class="my-auto"
                  contain
                  height="35px"
                  src="@/assets/img/logo.png"
                ></v-img>
              </v-toolbar-items>
            </v-toolbar>
            <v-list>
              <template v-for="(item, index) in items">
                <template v-if="item.show">
                  <v-list-group
                    v-if="item.subItems"
                    :key="index"
                    :id="index"
                    eager
                    ripple
                    :value="true"
                  >
                    <template v-slot:activator>
                      <v-list-item-icon :class="'ml-7'">
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-for="(subItem, i) in item.subItems">
                      <v-list-item
                        v-if="subItem.show"
                        :key="i"
                        :to="subItem.to"
                        link
                      >
                        <v-list-item-icon :class="'ml-10'">
                          <v-icon>{{ subItem.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            subItem.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-group>

                  <v-list-item :to="item.to" :key="index" v-else link>
                    <v-list-item-icon :class="'ml-7'">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list>
            <!--     logout btn -->
            <v-btn
              block
              text
              @click="isShowVerifyModal = true"
              class="rounded-0"
              color="#A65959"
            >
              <v-icon class="mr-3">mdi-logout-variant</v-icon>
              <span>
                {{ $_t("admin.components.drawer.logout") }}
              </span>
            </v-btn>
          </v-card>
        </v-dialog>

        <AdminHeader />
        <Notifications />
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { _t } from "@/tools/Utils";
import AdminHeader from "@/packages/admin/components/AdminHeader";
import Notifications from "@/components/app/Notifications";
export default {
  name: "Admin.layout",
  components: { Notifications, AdminHeader },
  data: () => ({
    isShowVerifyModal: false,
    drawer: true,
    dialog: false,
    mini: false,
  }),
  computed: {
    ...mapGetters({
      role: "authentication/role",
      itemsLength: "pagination/get_items_length",
      all: "admin/get_all",
    }),
    items() {
      return [
        {
          title: _t("admin.components.drawer.users"),
          to: { name: "admin.users" },
          icon: "mdi-account-outline",
          show: true,
        },
        {
          title: _t("admin.components.drawer.lessons"),
          to: { name: "admin.lessons" },
          show: [
            "Admin",
            "TeamLeader",
            "Teacher",
            "AcademicConsultant",
            "AssistantTeacher",
          ].includes(this.role),
          icon: "mdi-book-open-page-variant-outline",
        },
        {
          title: _t("admin.components.drawer.courses"),
          to: { name: "admin.courses" },
          show: [
            "Admin",
            "TeamLeader",
            "Teacher",
            "AcademicConsultant",
            "AssistantTeacher",
          ].includes(this.role),
          icon: "mdi-book-outline",
        },
        {
          title: _t("admin.components.drawer.classRoom"),
          icon: "mdi-google-classroom",
          show: [
            "Admin",
            "TeamLeader",
            "Teacher",
            "SchedulingAdmin",
            "FinancialAdmin",
            "AcademicConsultant",
            "AssistantTeacher",
          ].includes(this.role),
          // show: false,
          subItems: [
            {
              title: _t("attribute.addClass"),
              to: { name: "admin.class_room_create" },
              icon: "mdi-plus-box",
              show: ["Admin", "SchedulingAdmin"].includes(this.role),
            },
            {
              title: _t("attribute.table"),
              to: { name: "admin.class_room" },
              icon: "mdi-table",
              show: true,
            },
            {
              title: _t("admin.components.drawer.upcoming"),
              to: { name: "admin.class_upcoming" },
              icon: "mdi-new-box",
              show: true,
            },
          ],
        },
        {
          title: _t("admin.components.drawer.reports"),
          to: { name: "admin.reports" },
          icon: "mdi-chart-box",
          show: ["Admin", "FinancialAdmin", "TeamLeader"].includes(this.role),
        },
        {
          title: _t("admin.components.drawer.schedule"),
          to: { name: "admin.schedule" },
          icon: "mdi-calendar-check",
          show: [
            "Admin",
            "TeamLeader",
            "SchedulingAdmin",
            "Teacher",
            "AssistantTeacher",
          ].includes(this.role),
        },
        {
          title: _t("admin.components.drawer.finance"),
          icon: "mdi-currency-usd",
          show: [
            "Admin",
            "FinancialAdmin",
            "TeamLeader",
            "AcademicConsultant",
          ].includes(this.role),
          // show: false,
          subItems: [
            {
              title: _t("attribute.deposit"),
              to: { name: "finance.deposit" },
              icon: "mdi-cash-fast",
              show: ["Admin", "FinancialAdmin", "AcademicConsultant"].includes(
                this.role
              ),
            },
            {
              title: _t("attribute.settlement"),
              to: { name: "finance.settlement" },
              icon: "mdi-cash-sync",
              show: ["Admin", "TeamLeader", "FinancialAdmin"].includes(
                this.role
              ),
            },
            {
              title: _t("attribute.transfer"),
              to: { name: "finance.transfer" },
              icon: "mdi-bank-transfer",
              show: ["Admin", "FinancialAdmin", "AcademicConsultant"].includes(
                this.role
              ),
            },
            {
              title: _t("attribute.teacherFee"),
              to: { name: "finance.teacherFee" },
              icon: "mdi-cash",
              show: ["Admin", "FinancialAdmin", "TeamLeader"].includes(
                this.role
              ),
            },
          ],
        },
        {
          title: _t("admin.components.drawer.contract"),
          to: { name: "admin.contract" },
          icon: "mdi-file-sign",
          show: ["Admin", "FinancialAdmin", "AcademicConsultant"].includes(
            this.role
          ),
        },
        {
          title: _t("admin.components.drawer.notifications"),
          to: { name: "admin.notifications" },
          icon: "mdi-bell",
          show: true,
        },
      ];
    },
  },
  methods: {
    cancel() {
      this.isShowVerifyModal = false;
    },
    close() {
      this.drawer = false;
    },
    logout() {
      this.$store.dispatch("authentication/logout");
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    async getNotifications() {
      await this.$actions.getNotifications({ role: this.role, all: true });
    },
  },
  mounted() {
    this.getNotifications();
  },
  beforeRouteUpdate(to, from, next) {
    this.getNotifications();
    next();
  },
};
</script>

<style scoped>
.item-active {
  color: #fff;
  opacity: 1;
}
.item-active:hover {
  color: #4340da;
  opacity: 1;
}
.item-active:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 90%;
  background: #4340da;
  right: 0;
  left: 10%;
  bottom: 0;
  opacity: 1;
  z-index: -10;
  border-radius: 4px 0px 0px 4px;
}
.logout {
  position: absolute;
  bottom: 50px;
}
</style>
